// @flow
import React from 'react';
import useAppURLsByDevice from 'src/hooks/useAppUrlsByDevice';
import BoxIndex from '../BoxIndex/BoxIndex';
import styles from './AppDownload.module.scss';
import { trackAppDownload } from '../../utils';

const SP_URLS = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/applenew',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/applenew',
};

// clickArea is passed into GA to identify which button was pressed
const AppDownload = ({ text, isGray = true, wrapperStyle, clickArea }) => {
  const { appStoreUrl, playStoreUrl } = useAppURLsByDevice(SP_URLS);

  return (
    <BoxIndex
      anchorId="app_download"
      title=""
      isGray={isGray}
      wrapperStyle={wrapperStyle}
      style={{ padding: '39px 0 52px' }}
    >
      <div className={styles.appDownload}>
        <h3>
          {text || (
            <>
              ペイディアプリでお買い物を
              <br />
              もっと便利に。もっと楽しく。
            </>
          )}
        </h3>
        <nav>
          <a
            className=""
            href={appStoreUrl}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => trackAppDownload('App Store', clickArea)}
          >
            <img
              src={require('../../images/badge-app-store.png')}
              alt="Apple App Store - Paidy"
              width="140"
              height="42"
              loading="lazy"
            />
          </a>
          <a
            className=""
            href={playStoreUrl}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => trackAppDownload('Google Play', clickArea)}
          >
            <img
              src={require('../../images/badge-play-store.png')}
              alt="Google Play Store - Paidy"
              width="140"
              height="42"
              loading="lazy"
            />
          </a>
        </nav>
      </div>
    </BoxIndex>
  );
};

export default AppDownload;
